import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import DisclosureSearch from "@tightrope/ctadisclosuresearch"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import ChunkyFooter from "src/components/chunkyfooter"
import Styles from "./css/open2.module.scss";
import chatData from "./data/data.json";

const footerData = import("./data/footerData.json");

export default function chat() {
  return(
    <HomepageLayout>
    <Helmet>
    <title>Find Manuals to Read & Print</title>
    <style type="text/css">{`
#chunkyfooter-module--chunkyFooter .chunkyfooter-module--footerButton {
    background: #2196f3;
    color: #fafafa;
    border: none;
    font-size: 1.75rem;
    padding: .75rem 5rem;
    border-radius: 50px;
    letter-spacing: 0.5px;
    font-weight: 300 !important;
    margin: 1rem auto;
    cursor: pointer;
    transition: 0.3s ease;
    display: block;
    width: max-content;
  }

  #chunkyfooter-module--chunkyFooter .chunkyfooter-module--footerButton:hover {
    background: #0d5ea0;
    box-shadow: 1px 1px 4px #929292;
    }

  a.ctadisclosuresearch-module--bold {
      font-weight: normal!important;
      }

  #ctadisclosuresearch-module--ctadisclaimersearch {
      padding: 0;
      color: #333;
      text-align: center;
      margin: 0 auto !important;
      max-width: 554px;
      font-weight:400;

      p {
            line-height: 130%;
            font-size: 12px;
      }
    }

  #bmpage-module--bmPage p {
      font-size: 12px;
      font-weight:400;
    }
  footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;
    position: relative;
    color: #333;
    padding: 0;
    width: 100%;
    margin:0;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding: 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }
  @media only screen and (max-width: 768px) {

    footer {
      position: relative;
      display: flex;
      justify-content:center;
      align-items:center;
      color: #333;
      width: 100%;
      right:auto;
      bottom:auto;
  }

  footer ul {
    position: relative;
    display: flex;
    justify-content:center;
    align-items:center;
}

button.chat-module--cta:hover {
  background-color: #ff2020 !important;
  box-shadow: 1px 1px 4px #929292;
}


    `}
    </style>
    </Helmet>
    <section>
    <div id={Styles.chatgpt}>
          <div className={Styles.atf}>
            <div className={Styles.header}>
              <img src={chatData.logo} alt="Manuals Library" />
              <p>{chatData.browser}</p>
            </div>

            <div className={Styles.content}>
              <h1 className="hc_param">{chatData.headline}</h1>
              <h2>{chatData.subhead}</h2>
              <button className={Styles.cta} data-cy="cta" onClick = {() => window.triggerInstall()}>{chatData.cta}</button>
              <DisclosureSearch />
            </div>

            <div className={Styles.sliderWrap}>
              <div className={Styles.autoSliderContainer}>
                <ul className={Styles.autoSlider}>
                  <li>{chatData.item1}</li>
                  <li>{chatData.item2}</li>
                  <li>{chatData.item3}</li>
                  <li>{chatData.item4}</li>
                  <li>{chatData.item5}</li>
                </ul>
                <ul className={Styles.autoSlider}>
                  <li>{chatData.item1}</li>
                  <li>{chatData.item2}</li>
                  <li>{chatData.item3}</li>
                  <li>{chatData.item4}</li>
                  <li>{chatData.item5}</li>
                </ul>
                <ul className={Styles.autoSlider}>
                  <li>{chatData.item1}</li>
                  <li>{chatData.item2}</li>
                  <li>{chatData.item3}</li>
                  <li>{chatData.item4}</li>
                  <li>{chatData.item5}</li>
                </ul>
              </div>

              <div className={Styles.autoSliderContainer}>
                <ul className={Styles.autoSliderReverse}>
                  <li>{chatData.item6}</li>
                  <li>{chatData.item7}</li>
                  <li>{chatData.item8}</li>
                  <li>{chatData.item9}</li>
                  <li>{chatData.item10}</li>
                </ul>
                <ul className={Styles.autoSliderReverse}>
                  <li>{chatData.item6}</li>
                  <li>{chatData.item7}</li>
                  <li>{chatData.item8}</li>
                  <li>{chatData.item9}</li>
                  <li>{chatData.item10}</li>
                </ul>
                <ul className={Styles.autoSliderReverse}>
                  <li>{chatData.item6}</li>
                  <li>{chatData.item7}</li>
                  <li>{chatData.item8}</li>
                  <li>{chatData.item9}</li>
                  <li>{chatData.item10}</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
      <ChunkyFooter data={footerData}><DisclosureSearch /></ChunkyFooter>
      <RawFooter />
      </section>
    </HomepageLayout>
  )
}
